var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "h2",
    { staticClass: "text-center text-sm-left" },
    [
      _vm._t("default"),
      _c("span", { staticClass: "text--grey text-lighten-2" }, [
        _vm._v(_vm._s(_vm.eshop)),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }