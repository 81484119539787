var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { "open-delay": _vm.delay, bottom: "", "max-width": _vm.width },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "span",
                  _vm._g(_vm._b({}, "span", attrs, false), on),
                  [_vm._t("default")],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
    },
    [
      _c(
        "p",
        { staticClass: "tip__body mb-0" },
        [_vm.tip ? _c("span", [_vm._v(_vm._s(_vm.tip))]) : _vm._t("tip")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }