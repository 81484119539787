import { get } from '@/services/api'

const state = {
  addons: []
};

const getters= {
  getAddons: state => state.addons,
  hasAddonActive: state => addonID => state.addons.some(addon => addon.id === addonID || addon.name === addonID),
}

const actions= {
  fetchAddons({commit}) {
    return new Promise((resolve, reject) => {
      get(process.env.VUE_APP_PRINT_SERVER + '/addons/?ignore_visibility=true&active_only=true').then(res => {
        commit('SET_ACTIVE_ADDONS', res.data.addons)
        resolve()
      }).catch(err => reject(err))
    })
  },

};

const mutations= {
  SET_ACTIVE_ADDONS: (state, addons) => { 
    state.addons = addons; 
  },

};



export default {
  namespaced: true,
  name: 'addons',
  state,
  getters,
  actions,
  mutations,
}
