var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      attrs: {
        "content-class": "pb-0",
        timeout: "-1",
        right: "",
        vertical: "",
        "multi-line": "",
        "min-width": "150px",
      },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    staticClass: "grey--text",
                    attrs: { text: "", small: "" },
                    on: {
                      click: function ($event) {
                        _vm.showUpdateSnack = false
                      },
                    },
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [_vm._v(" Později ")]
              ),
              _c(
                "v-btn",
                _vm._b(
                  {
                    staticClass: "info--text",
                    attrs: { text: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("reload")
                      },
                    },
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [_vm._v(" Ano ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showUpdateSnack,
        callback: function ($$v) {
          _vm.showUpdateSnack = $$v
        },
        expression: "showUpdateSnack",
      },
    },
    [_vm._v(" 🚀 K dispozici je nová verze aplikace. Chcete ji načíst? ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }