var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c(
        "div",
        { staticClass: "spinner" },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "primary" },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }