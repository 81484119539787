import { get, post, del, patch } from '@/services/api'

const state = {
  types: [],
  documents: [],
  docDetail: {},
  loading: false,
};

const getters= {
  getMovementTypes: state => state.types,
  getMovementNameByType: state => type => state.types.find(docType => docType.document_id == type),
  getDocuments: state => state.documents,
  getDocumentById: state => id => state.documents.find(doc => doc.document_id == id),
  getDocDetail: state => state.docDetail,
  isDocLoading: state => state.loading,
};

const actions= {
  fetchDocTypes({commit}) {
    get('/movement_documents/types/').then(res => {
      commit('SET_TYPES', res.data);
    });
  },

  fetchDocs({commit}) {
    return new Promise((resolve, reject) => {
      get('/movement_documents/').then(res => {
        commit('SET_DOCUMENTS', res.data);
        resolve()
      }).catch(() => {
        reject();
      })
    })
  },

  fetchDocById({commit}, id) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true);
      get(`/movement_documents/${id}`).then(res => {
        commit('SET_DETAIL', res.data);
        commit('SET_LOADING', false);
        resolve()
      }).catch(() => {
        commit('SET_LOADING', false);
        reject();
      })
    })
  },

  createDoc({commit}, data) {
    return new Promise((resolve, reject) => {
      post('/movement_documents/', data).then(res => {
        commit('ADD_DOCUMENT', res.data);
        resolve(res)
      }).catch(err => {
        reject(err);
      })
    })
  },

  updateDoc({dispatch}, data) {
    return new Promise((resolve, reject) => {
      patch(`/movement_documents/${data.document_id}`, data).then(res => {
        dispatch('fetchDocById', data.document_id);
        resolve(res)
      }).catch(err => {
        reject(err);
      })
    })
  },

  deleteDoc({dispatch}, rule_id) {
    return new Promise((resolve, reject) => {
      del(`/movement_documents/${rule_id}`).then(res => {
        dispatch('fetchDocs');
        resolve(res)
      }).catch(err => {
        reject(err);
      })
    })
  }
};

const mutations= {
  SET_TYPES: (state, types) => state.types = types,
  ADD_DOCUMENT: (state, doc) => state.documents.push(doc),
  SET_DOCUMENTS: (state, docs) => state.documents = docs,
  SET_DETAIL: (state, doc) => state.docDetail = doc,

  SET_LOADING: (state, status) => state.loading = status,
};



export default {
  //namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
