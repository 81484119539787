// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../node_modules/@vue/vue-loader-v15/lib/loaders/stylePostLoader.js!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!./styles/app.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cursor-pointer {\n  cursor: pointer;\n}\n.cursor-normal {\n  cursor: default;\n}\n.cursor-move {\n  cursor: move;\n}\n#app {\n  font-family: \"Roboto\", Avenir, Helvetica, Arial, sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  display: flex;\n  color: #2c3e50;\n}\n@media only screen and (min-width: 821px) {\n.content:not(.no-padding) {\n    padding: 1.5rem 2.5rem 2.5rem 4rem;\n}\n}\n@media only screen and (max-width: 820px) {\n.content:not(.no-padding) {\n    padding: 0.5rem 2rem;\n}\n}\n.content {\n  width: 100%;\n}\n#nav {\n  padding: 30px;\n}\n#nav a {\n  font-weight: bold;\n  color: #2c3e50;\n}\n#nav a.router-link-exact-active {\n  color: #42b983;\n}\n.image-zoomable.active {\n  transform: scale(5);\n  position: absolute;\n  z-index: 9999;\n  background-color: white;\n  border: 1px solid rgba(200, 200, 200, 0.3);\n  transition: 350ms;\n}\n.image-zoomable.active .v-image__image {\n  background-size: contain !important;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
