var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-bottom-sheet",
        {
          attrs: {
            "hide-overlay": "",
            inset: "",
            persistent: _vm.isPersistent,
          },
          model: {
            value: _vm.showScanner,
            callback: function ($$v) {
              _vm.showScanner = $$v
            },
            expression: "showScanner",
          },
        },
        [
          _c(
            "v-sheet",
            {
              staticClass: "text-center camera-drawer",
              class: { "pa-5": !_vm.activeCamera },
              attrs: { id: "camera-drawer" },
            },
            [
              _vm.showConfirm
                ? _c(
                    "v-sheet",
                    {
                      staticClass: "scan-confirm-dialog text-center pa-5 mt-5",
                    },
                    [
                      _vm._v(" Byl naskenován kód: "),
                      _c("strong", [_vm._v(_vm._s(_vm.decodedText))]),
                      _vm._v(" chcete jej potvrdit? "),
                      _c(
                        "div",
                        { staticClass: "mt-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "error", text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.resumeScanner()
                                },
                              },
                            },
                            [_vm._v("Zrušit")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.manualConfirm()
                                },
                              },
                            },
                            [_vm._v("Potvrdit")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "camera-box" }, [
                _vm.activeCamera
                  ? _c(
                      "div",
                      { staticClass: "overlay-icon pa-2 d-flex" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.changeCamera()
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { color: "white" } }, [
                              _vm._v("mdi-camera-retake"),
                            ]),
                          ],
                          1
                        ),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                _vm.showScanner = false
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { color: "white" } }, [
                              _vm._v("mdi-close"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", {
                  attrs: { id: "reader", height: "200px", width: "200px" },
                }),
              ]),
              !_vm.activeCamera
                ? _c("v-select", {
                    attrs: {
                      label: "Vyberte kameru",
                      items: _vm.getCameraNames,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.startScanner()
                      },
                    },
                    model: {
                      value: _vm.activeCamera,
                      callback: function ($$v) {
                        _vm.activeCamera = $$v
                      },
                      expression: "activeCamera",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }