import { get, post, del, patch } from "@/services/api";

const state = {
  sectors: [],
  locations: [],
  productsInLocation: [],

  sectorsLoading: false,
  locationsLoading: false,
};

const getters = {
  getSectors: state => state.sectors.sort((a,b) =>  a.order - b.order),
  getSectorById: state => id => state.sectors.find(s => s.id == id),
  getSectorByName: state => name => state.sectors.find(s => s.sector_name == name),
  getSectorsSelect: state => state.sectors.map(s => ({value: s.id, text: s.sector_name})),
  getProductsInLocation: state => state.productsInLocation,

  getLocations: state => state.locations,
  getLocationsSelect: state => state.locations.map(l => ({value: l.id, text: l.name})),
  getLocationById: state => id => state.locations.find(l => l.id == id),
  locationsInSector: (state) => (sector_id) => {
      let locations = [];
      state.locations.forEach(item => {
          if (item.sector.id == sector_id) locations.push(item);
        });

      locations.sort(function(a, b){
          if(a.name < b.name) { return -1; }
          if(a.name > b.name) { return 1; }
          return 0;
      })

      return locations;
  },

  getSectorsLoading: state => state.sectorsLoading,
  getLocationsLoading: state => state.locationsLoading,
};

const actions = {

  setSectors({commit}, sectors) {
      commit('SET_SECTORS', sectors);
  },

  fetchSectors({commit}) {
    return new Promise((resolve) => {
      commit('SET_SECTORS_LOADING', true);
      get('/sector/').then((res) => {
          commit('SET_SECTORS', res.data);
          commit('SET_SECTORS_LOADING', false);
          resolve();
      });
    });
  },

  fetchLocations({commit}) {
    return new Promise((resolve) => {
        commit('SET_LOCATIONS_LOADING', true);
        get('/location/').then((res) => {
            commit('SET_LOCATIONS', res.data);
            commit('SET_LOCATIONS_LOADING', false);
            resolve();
        });
    });
  },

  fetchLocationProducts({commit}, locationId) {
      return new Promise((resolve) => {
          get(`/location/${locationId}/list-products/`).then((res) => {
            commit('SET_LOCATION_PRODUCTS', res.data);
            resolve();
          });
      });
  },

  updateSector({commit}, sector) {
    return new Promise((resolve, reject) => {
        patch('/sector/', sector).then((res) => {
          if(res.status != 400) {
            commit('UPDATE_SECTORS', res.data);
            resolve( true );
          } else {
            reject(res.data.detail);
          }
        });
    });
  },

  createSector({commit}, sector) {
    return new Promise((resolve) => {
        post('/sector/', sector).then((res) => {
          commit('ADD_SECTORS', res.data);
          resolve( res.data );
        });
    });
  },

  deleteSector({commit}, sector) {
    return new Promise((resolve, reject) => {
        del(`/sector/${sector.id}/`).then(() => {
          commit('REMOVE_SECTOR', sector.id);
          resolve( true );
        }).catch((err) => {
          reject(err);
        });
    });
  },

  async deleteLocationAndEmptySector({dispatch, getters}, {location, onlyPosition}) {
    return new Promise((resolve, reject) => {
      del(`/location/${location.id}/`).then(() => {
          dispatch('fetchLocations').then(() => {
              if(!onlyPosition)
                if(getters.locationsInSector(location.sector.id).length == 0) dispatch('deleteSector', location.sector).then(() => resolve());
              else resolve();
          });
      }).catch((err) => {
        reject(err);
      });
    });

  },

  togglePicking({commit}, sectorId) {
    del(`/sector/toggle_picking/${sectorId}/`).then(res => {
        commit('SET_SECTORS', res.data);
    })
  },

  toggleSarze({commit}, sectorId) {
    return new Promise((resolve, reject) => {
      del(`/sector/toggle_povolit_na_jedne_pozici_vice_sarzi/${sectorId}/`).then(res => {
          commit('SET_SECTORS', res.data);
          resolve();
      }).catch(err => {
          reject(err.payload);
      });
    })
  },

  toggleStock({commit}, sectorId) {
    return new Promise((resolve, reject) => {
      del(`/sector/toggle_neskladovy/${sectorId}/`).then(res => {
          commit('SET_SECTORS', res.data);
          resolve();
      }).catch(err => {
          reject(err.payload);
      });
    })
  },


};

const mutations = {
  SET_SECTORS: (state, sectors) => state.sectors = sectors,
  UPDATE_SECTORS: (state, sector) => {
    const index = state.sectors.map(s => s.id).indexOf(sector.id);
    state.sectors[index] = sector;
  },
  ADD_SECTORS: (state, sector) => state.sectors.push(sector),
  REMOVE_SECTOR: (state, id) => state.sectors = state.sectors.filter(s => s.id !== id),

  SET_LOCATIONS: (state, locations) => state.locations = locations,
  SET_LOCATION_PRODUCTS: (state, products) =>{ state.productsInLocation = products.map(p => {return {details: {}, ...p}}) },
  SET_SECTORS_LOADING: (state, status) => state.sectorsLoading = status,
  SET_LOCATIONS_LOADING: (state, status) => state.locationsLoading = status,
};

export default {
  state,
  getters,
  actions,
  mutations,
}
